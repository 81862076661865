body {
  font-family: 'Open Sans', sans-serif;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.quiz-card {
  position: relative;
}
.display-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.sidebar-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  background: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
}

.lesson-column,
.file-column {
  padding: 15px;
  margin: 5px 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
}

.lesson-column:hover,
.file-column:hover {
  background: #e3f2fd;
}

.file-column.active {
  background: #1976d2;
  color: white;
}

.mark-complete {
  margin-top: 15px;
  padding: 10px;
  background: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.mark-complete:hover {
  background: #218838;
}

.main-content-popup {
  width: 70%;
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.file-content {
  text-align: center;
}

.file-content img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.display-content-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar (Columns) */
.sidebar-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  max-width: 250px;
  background: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 10px;
  overflow-y: auto;
}

.lesson-column,
.file-column {
  padding: 10px;
  margin-bottom: 5px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}

.lesson-column:hover,
.file-column:hover {
  background: #e3e3e3;
}

.lesson-column.active,
.file-column.active {
  background: #007bff;
  color: white;
}

.mark-complete {
  margin-top: 10px;
  padding: 10px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.mark-complete:hover {
  background: #218838;
}

/* Main Content */
.main-content-popup {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  
}

/* Responsive Design */
@media (max-width: 992px) {
  .display-content-container {
    flex-direction: column;
    height: auto;
  }

  .sidebar-column {
    width: 100%;
    max-width: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .lesson-column,
  .file-column {
    flex: 1 1 45%;
    margin: 5px;
  }

  .main-content-popup {
    padding: 15px;
    width: 100%;
  }
}

.main-content-popup {
  max-height: 80vh; /* Allows enough space for large content */
  overflow-y: auto; /* Enables scrolling if content is too long */
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.lesson-content {
  word-wrap: break-word; /* Ensures text wraps properly */
  white-space: normal; /* Allows text to break into multiple lines */
}

@media screen and (max-width: 768px) {
  .main-content-popup {
    max-height: none; /* Allows full expansion on small screens */
  }
}

.quiz-results-modal .modal-content {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 576px) {
  .lesson-column,
  .file-column {
    flex: 1 1 100%;
    margin: 3px 0;
  }
}


.delete-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: red;
}

.delete-icon:hover {
  color: darkred;
}
.card {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}


img {
  object-fit: cover;
}

.link:hover {
  color: green;
}

.my-div.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}


.my-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.my-list li {
  background-color: #222;
  color: #fff;
  padding: 10px;
  margin: 9px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.upload-label {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.upload-label:hover {
  background-color: #0056b3;
}


video,
image {
  max-width: 100vw;
  max-height: 80vh;
}

.profile-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.profile-pics img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.profile-pics i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-file {
  width: 100%;
  max-width: 200px;
}

.message-file video,
img {
  width: 100%;
  height: 100%;
}

.messages {
  border-top: 5px solid #333;
  border-bottom: 5px solid #333;
  border-radius: 8px;
  max-width: 100%;
  max-height: 80vh;
  overflow: scroll;
}

.profile-pic-message-chat {
  display: flex;
  align-content: center;
}

@media (max-width: 521px) {

  .messages,
  .chat-message {
    width: 80vw;
  }
}

.quiz-grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

@media (max-width: 800px) {
  .quiz-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.message {
  border-bottom: 3px solid black;
  border-radius: 8px;
  color: #fff;
  padding: 7px;
}

@media (max-width: 410px) {
  .message {
    margin: 0;
    padding: 10px;
  }

  .messages {
    width: 100%;
    margin: 0;
  }

  .message-file {
    width: 100%;
    height: 100%;
  }

  .message-file video,
  img {
    width: 100%;
    height: 100%;
  }

}

.image-container {
  position: relative !important;
  color: #393f4d;
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 600px;
  max-width: 600px;
  display: block;
}

.home-content {
  position: absolute;
  width: 50%;
  top: 60%;
  left: 74%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: start;
}

.small-screen-content {
  display: none;
}

@media (max-width: 560px) {
  .home-content {
    display: none;
  }

  .small-screen-content {
    display: inline-block;
  }
}

.post {
  border-bottom: #333 solid 3px;
  border-top: #333 solid 3px;
  border-radius: 6px;
  max-height: 500px;
  padding: 5px;
  overflow: scroll;
  scroll-behavior: smooth;

}

.player-wrapper {
  border: 2px solid black;
  object-fit: cover;
}

.comment-profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.comment-profile-name {
  display: flex;
  align-items: start;
}

.audio audio {
  width: 100%;
  height: 40px;
}

.wrapper {
  border-bottom: 3px solid #555;
}

.quiz-input-question {
  padding: 7px;
  border-radius: 6px;
}

.open-trivia-quiz {
  margin: 40px;
}

.trivia-quiz-questions ul li {
  list-style: none;
}

.choose-category,
.choose-questions {
  width: 400px;
  margin: 20px 0;
}

.answer-button {
  margin-left: 0;
}

@media (max-width: 470px) {
  .open-trivia-quiz {
    margin: 20px;
  }

  .choose-category,
  .choose-questions {
    width: 90%;
    margin: 20px 10px;
  }
}

@media (max-width: 320px) {
  .open-trivia-quiz {
    margin: 20px 6px;
  }

  .choose-category,
  .choose-questions {
    width: 90%;
    margin: 20px 6px;
  }
}

@media (max-width: 250px) {
  .open-trivia-quiz {
    margin: 10px 0;
  }

  .choose-category,
  .choose-questions {
    width: 100%;
    margin: 20px 0;
  }
}

.api-quizzes {
  margin: 50px;
}

@media (max-width: 360px) {
  .api-quizzes {
    margin: 10px;
  }
}

@media (max-width: 200px) {
  .api-quizzes {
    margin: 4px;
  }
}


/* Add this to your existing styles.css or in a style tag within your component */
.correct {
  background-color: green;
}

.wrong {
  background-color: red;
}

#answerFeedback {
  font-weight: bold;
  margin-top: 10px;
  color: green;
  /* Change the color of the feedback text to green */
}


.create-quiz-input {
  height: 40px;
  width: 100%;
  border-radius: 6px;
}

.answer-text {
  height: 40px;
  width: 80%;
  border-radius: 6px;
}

.subjects-filter-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 600px;
}

@media (max-width: 370px) {
  .subjects-filter-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}


.subjects-cont {
  width: 100%;
  height: 100px;
}

.star-icons {
  color: gold;
  /* Set the color of the stars */
  font-size: 12px;
  /* Set the size of the stars */
}

.profile-info-grid {
  display: flex;
}

@media (max-width: 460px) {
  .profile-info-grid {
    flex-direction: column;
  }
}

.subjects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.subject {
  width: 100%;
  max-width: 400px;
}

.subject img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

@media (max-width:1020px) {
  .subjects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:635px) {
  .subjects {
    grid-template-columns: 1fr;
  }
}


/* Add this to your component's CSS or a separate CSS file */
.badge {
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  z-index: 99;
}

.badge-enrolled {
  background-color: #4caf50;
  /* Green color for enrolled */
}
.badge-waiting {
  background-color: #705ce4;
  /* Red color for not enrolled */
}

.badge-not-enrolled {
  background-color: #f44336;
  /* Red color for not enrolled */
}

.custom-modal {
  width: 90%;
  max-width: 90%;
  height: 90%;
  max-height: 90%;
  margin: auto;
}

.create-community-image-list-container {
  display: flex;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}

@media(max-width:770px) {
  .create-community-image-list-container {
    display: flex;
    flex-direction: column-reverse;
  }
}

.lesson-delete {
  display: grid;
  grid-template-columns: 99fr 1fr;
  grid-column-gap: 20px;
}

.subject-materials-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-around;
}

.material-container {
  width: 100%;
  margin-top: 40px;
  max-width: 400px;
  border-radius: 8px;
}

.naming-container {
  width: 100%;
  height: 100%;
  max-height: 45px;
  border-radius: 8px;
}



/* Custom CSS for resizable React Quill editor */
.react-quill {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */

  width: 100%; /* Set width to fill its container */
}


.react-quill .ql-toolbar {
  border-bottom: 1px solid #ccc;
}

.react-quill .ql-container {
  min-height: 40px;
}

.react-quill .ql-editor {
  font-size: 16px;
  padding: 10px;

  overflow-y: auto;
}

/* Custom styles for file input */
input[type="file"] {
  display: block;
  margin-top: 10px;
}

.striped-table {
  border-collapse: collapse;
  width: 100%;
}

.striped-table th,
.striped-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.striped-table th {
  background-color: #000;
  color: #fff;
}

.striped-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.striped-table tbody tr:nth-child(odd) {
  background-color: #fff;
}


.instructor-register-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

@media (max-width: 992px) {
  .instructor-register-container {
    grid-template-columns: 1fr;
  }
}

.instructors-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

@media (max-width: 992px) {
  .instructors-container {

    grid-template-columns: repeat(2, 1fr);

  }
}

@media (max-width: 460px) {
  .instructors-container {

    grid-template-columns: 1fr;

  }
}


.file-inputs-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 992px) {
  .file-inputs-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.file-input-container {
  position: relative;
  width: 250px;
  height: 250px;
  background-color: #333;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-input-container input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}



.plus-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 48px;
}



.file-info {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  /* Prevent line breaks */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Show ellipsis if text overflows */
  width: calc(100% - 40px);
  /* Subtract padding */
}

.payment-input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
}
.gold-star {
  color: gold;
}
/* Add these styles to your CSS file */

.btn-animate {
  transition: background-color 0.3s ease, color 0.3s ease;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
}

.btn-animate:hover {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
  color: #fff;
}

/* styles.css */
@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.subject {
  opacity: 0;
  transition: opacity 0.5s;
}

.subject.visible {
  animation: slideInBottom 0.5s ease-out forwards, popIn 0.5s ease-out forwards;
}
/* Add these styles to your CSS file */
.alternate-background-1 {
  background-color: #f8f9fa; /* Light background */
}

.alternate-background-2 {
  background-color: #e9ecef; /* Darker background */
}
.gold-badge {
  color: gold;
}

.silver-badge {
  color: silver;
}

.bronze-badge {
  color: bronze;
}
/* CSS to scale down images and objects in ReactQuill */
.ql-editor img {
  max-height: 200px;
  width: auto;
  object-fit: contain;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-popup {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #00b4d8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.chat-message {
  display: flex;
  flex-direction: column;
}

.message-bubble {
  background-color: #f8f9fa;
  max-width: 75%;
  position: relative;
}

.user-message {
  background-color: #d1e7dd;
  align-self: flex-end;
}

.other-message {
  background-color: #c2f1ee;
  align-self: flex-start;
}

.chat-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.reply-btn,
.delete-btn {
  cursor: pointer;
  font-size: 14px;
}

.reply-btn:hover,
.delete-btn:hover {
  text-decoration: underline;
}

.replies-section {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 5px;
}

.replies-toggle {
  cursor: pointer;
}

.replies-list {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 5px;
}

.reply-message {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.reply-message:last-child {
  border-bottom: none;
}
.install-btn {
  background-color: #008CBA;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.install-btn:hover {
  background-color: #006f8a;
}

